<template>
  <div :class="$style.container">
    <v-read-more v-if="description" size="md" :content="description" name="more" />
    <v-no-data v-else size="sm" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import VReadMore from '@layouts/VReadMore.vue'
import VNoData from '@layouts/VNoData.vue'

export default Vue.extend({
  name: 'ProductDescription',
  components: { VReadMore, VNoData },
  computed: {
    ...mapGetters('Post', ['postProduct']),
    description() {
      return this.postProduct?.description ?? ''
    },
  },
})
</script>

<style lang="scss" module>
.container {
  display: grid;
  row-gap: 15px;
  padding: 21px;
  box-shadow: inset 0 -1px 0 black;
}
</style>
