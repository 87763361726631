<template>
  <div :class="$style.container">
    <transition name="bottom" mode="out-in">
      <div v-if="postProduct" :class="[$style.content, isProductInCart && $style.isCounter]">
        <v-counter
          v-if="isProductInCart"
          :value="cartProductCount(postProduct.id)"
          :class="$style.counter"
          @add="setCartProductCount({ id: postProduct.id, type: 'increment' })"
          @remove="setCartProductCount({ id: postProduct.id, type: 'decrement' })"
        />
        <v-button
          :size="isProductInCart ? 'xl' : 'md'"
          color="primary"
          type="submit"
          :class="$style.button"
          @click="onButton"
        >
          <span v-if="isProductInCart">{{ $t('cart.view') }}</span>
          <template v-else>
            <span :class="$style.text">{{ $t('addToCart') }}</span>
            <span :class="$style.price">{{ currency }}{{ currentPrice }}</span>
          </template>
        </v-button>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VButton from '@elements/VButton.vue'
import VCounter from '@layouts/VCounter.vue'

export default Vue.extend({
  name: 'ProductFooter',
  components: { VButton, VCounter },
  computed: {
    ...mapGetters('App', ['appCurrencySymbol']),
    ...mapGetters('Post', ['postProduct']),
    ...mapGetters('Cart', ['cartProductCount']),
    currency() {
      return this.postProduct?.app?.currency?.symbol || this.appCurrencySymbol()
    },
    currentPrice() {
      return this.postProduct?.price ?? 0
    },
    isProductInCart() {
      return !!this.cartProductCount(this.postProduct.id)
    },
  },
  methods: {
    ...mapMutations('Cart', ['setCartProductCount', 'setCartProduct']),
    ...mapMutations('Drawers', ['setDrawer']),
    onButton() {
      if (!this.isProductInCart) {
        this.setCartProduct(this.postProduct)
      } else {
        this.setDrawer({ name: 'cart', isVisible: true })
      }
    },
  },
})
</script>

<style lang="scss" module>
.container {
  padding: 6px;
  background: white;
  box-shadow: 0 -2px 5px rgba(black, 0.08);
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;

  &.isCounter {
    grid-template-columns: auto 1fr;
  }
}

.counter {
  align-self: center;
  height: 100%;
  padding: 11px 15px;
  border: 1px solid black;
  border-radius: 6px;
}

.button {
  display: grid;
  grid-template-columns: 1fr auto;

  & .text {
    align-self: center;
    font-size: 15px;
  }

  & .price {
    box-sizing: border-box;
    padding: 3px 9px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    border: 1px solid $secondary;
    border-radius: 12px;
  }
}
</style>
