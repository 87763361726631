<template>
  <section :class="[$style.section, pageLoading && $style.loading]">
    <template v-if="!pageLoading">
      <div :class="$style.content">
        <VPostPreview :post="post" is-footer />
        <VTabs :tabs="tabs" :active-index.sync="tabIndex" color="default" type="relative">
          <template #tab-description>
            <ProductDescription />
          </template>
          <template #tab-details>
            <ProductDetails />
          </template>
        </VTabs>
      </div>
      <ProductFooter />
    </template>
    <PPost v-else />
  </section>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VPostPreview from '@layouts/postPreview/VPostPreview.vue'
import VTabs from '@layouts/VTabs.vue'
import PPost from '@placeholders/PPost.vue'
import ProductDescription from './list/ProductDescription.vue'
import ProductDetails from './list/ProductDetails.vue'
import ProductFooter from './list/ProductFooter.vue'

export default Vue.extend({
  name: 'Product',
  components: {
    VPostPreview,
    PPost,
    VTabs,
    ProductDetails,
    ProductFooter,
    ProductDescription,
  },
  data() {
    return {
      tabIndex: 0,
      tabs: [
        { value: this.$t('description'), name: 'description' },
        { value: this.$t('details'), name: 'details' },
      ],
      backRoute: null,
    }
  },
  computed: {
    ...mapGetters('Post', ['post']),
    ...mapGetters('Page', ['pageLoading']),
    postRouteId() {
      return this.$route?.params?.id ?? null
    },
  },
  methods: {
    ...mapActions('Post', ['getPost']),
    // ...mapActions('Reviews', ['getReviews']),
    ...mapMutations('Page', ['setPageTitle']),
  },
  async mounted() {
    this.setPageTitle({ name: 'shop' })
    if (!this.post) {
      await this.getPost(this.postRouteId)
    }
  },
})
</script>
<style lang="scss" module>
.section {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  overflow: hidden;
  row-gap: 0;

  &.loading {
    grid-template-rows: auto;
    height: auto;
  }
}

.content {
  position: relative;
  display: grid;
  height: 100%;
  overflow-y: auto;
}
</style>
