<template>
  <div :class="$style.container">
    <template v-if="properties">
      <!--
      // TODO: Deciphering the values of the "type" field from @love_northern_light
      public const TYPE_STRING = 1; Strings (255 characters)
      public const TYPE_TEXT = 2; Text
      public const TYPE_INT = 3; Signed integer
      public const TYPE_FLOAT = 4; Signed float
    -->
      <div v-for="{ id, type, label, value } of properties" :key="id">
        <div :class="$style.content">
          <p :class="$style.property">{{ label }}</p>
          <p :class="[$style.property, $style.bold]">
            {{ type === 4 ? Math.round(value) : value }}
          </p>
        </div>
        <div :class="$style.separator"></div>
      </div>
    </template>
    <v-no-data v-else size="sm" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import VNoData from '@layouts/VNoData.vue'

export default Vue.extend({
  name: 'ProductDetails',
  components: { VNoData },
  ...mapGetters('Post', ['postProduct']),
  properties() {
    return this.product?.properties?.length ? this.product?.properties : null
  },
})
</script>

<style lang="scss" module>
.container {
  display: grid;
  row-gap: 15px;
  padding: 21px;
  box-shadow: inset 0 -1px 0 black;
}

.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
}

.property {
  padding-bottom: 12px;
  color: black;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  &.bold {
    font-weight: 500;
  }
}

.separator {
  width: 100%;
  border-top: 1px solid $secondary-light;
}
</style>
