// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProductFooter_container_1FNxR{padding:6px;background:#fff;box-shadow:0 -2px 5px rgba(0,0,0,.08)}.ProductFooter_content_1Utv6{display:grid;grid-template-columns:1fr;-moz-column-gap:10px;column-gap:10px}.ProductFooter_content_1Utv6.ProductFooter_isCounter_3i0lS{grid-template-columns:auto 1fr}.ProductFooter_counter_2hy-S{align-self:center;height:100%;padding:11px 15px;border:1px solid #000;border-radius:6px}.ProductFooter_button_2DZ0_{display:grid;grid-template-columns:1fr auto}.ProductFooter_button_2DZ0_ .ProductFooter_text_3iswf{align-self:center;font-size:15px}.ProductFooter_button_2DZ0_ .ProductFooter_price_3uCYk{box-sizing:border-box;padding:3px 9px;font-weight:500;font-size:12px;line-height:18px;border:1px solid var(--supster-secondary);border-radius:12px}", ""]);
// Exports
exports.locals = {
	"container": "ProductFooter_container_1FNxR",
	"content": "ProductFooter_content_1Utv6",
	"isCounter": "ProductFooter_isCounter_3i0lS",
	"counter": "ProductFooter_counter_2hy-S",
	"button": "ProductFooter_button_2DZ0_",
	"text": "ProductFooter_text_3iswf",
	"price": "ProductFooter_price_3uCYk"
};
module.exports = exports;
