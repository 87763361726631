// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Product_section_MfX00{display:grid;grid-template-rows:1fr auto;height:100%;overflow:hidden;row-gap:0}.Product_section_MfX00.Product_loading_2yE69{grid-template-rows:auto;height:auto}.Product_content_XNVUU{position:relative;display:grid;height:100%;overflow-y:auto}", ""]);
// Exports
exports.locals = {
	"section": "Product_section_MfX00",
	"loading": "Product_loading_2yE69",
	"content": "Product_content_XNVUU"
};
module.exports = exports;
