// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProductDetails_container_1UZ9Z{display:grid;row-gap:15px;padding:21px;box-shadow:inset 0 -1px 0 #000}.ProductDetails_content_2PM6r{display:grid;grid-template-columns:repeat(2,1fr);-moz-column-gap:10px;column-gap:10px}.ProductDetails_property_2eqZD{padding-bottom:12px;color:#000;font-weight:400;font-size:12px;line-height:18px}.ProductDetails_property_2eqZD.ProductDetails_bold_25FMc{font-weight:500}.ProductDetails_separator_1uTTs{width:100%;border-top:1px solid #e5e3e3}", ""]);
// Exports
exports.locals = {
	"container": "ProductDetails_container_1UZ9Z",
	"content": "ProductDetails_content_2PM6r",
	"property": "ProductDetails_property_2eqZD",
	"bold": "ProductDetails_bold_25FMc",
	"separator": "ProductDetails_separator_1uTTs"
};
module.exports = exports;
