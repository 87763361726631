// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProductDescription_container_1j95O{display:grid;row-gap:15px;padding:21px;box-shadow:inset 0 -1px 0 #000}", ""]);
// Exports
exports.locals = {
	"container": "ProductDescription_container_1j95O"
};
module.exports = exports;
